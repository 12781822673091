<template>
  <UForm ref="form" :schema="schema" :state="state" :validate="validate" class="grid grid-cols-1 md:grid-cols-2 gap-8" :class="[formClass]" @submit="onSubmit" @error="onError">
    <div v-if="blok?.showNewCustomerField" class="md:col-span-2 flex flex-col gap-7">
      <div class="md:max-w-80">
        <UFormGroup label="Do you have an existing account?" name="customer_status" required>
          <USelectMenu v-model="state.customer_status" :options="customer_status" option-attribute="name"> </USelectMenu>
        </UFormGroup>
      </div>
    </div>

    <template v-if="showForm">
      <UFormGroup label="First Name" name="first_name">
        <UInput v-model="state.first_name" />
      </UFormGroup>
      <UFormGroup label="Last Name" name="last_name">
        <UInput v-model="state.last_name" />
      </UFormGroup>
      <UFormGroup label="Country" name="country">
        <USelectMenu v-model="state.country" :options="countries" option-attribute="name"> </USelectMenu>
      </UFormGroup>
      <UFormGroup label="Mobile Number" name="phone">
        <UInput v-model="state.phone" :input-class="inputPhonePadding" @input="onPhoneInput">
          <template #leading>
            <span class="text-lg" v-text="`+${state.country.dial}`"></span>
          </template>
        </UInput>
        <template #error="{ error }">
          <span v-html="error"></span>
        </template>
      </UFormGroup>
      <UFormGroup label="Email Address" name="email">
        <UInput v-model="state.email" type="email" @change="validateDomain" />
        <template #error="{ error }">
          <span v-html="error"></span>
        </template>
      </UFormGroup>

      <UFormGroup label="How will you use Kudosity?" name="platform_use">
        <USelectMenu v-model="state.platform_use" :options="platform_use" option-attribute="label" value-attribute="value"> </USelectMenu>
      </UFormGroup>

      <UFormGroup label="Company Name" name="company">
        <UInput v-model="state.company" />
      </UFormGroup>

      <UFormGroup label="Company Website" name="company_website">
        <UInput v-model="state.company_website" @change="validateDomain" />
      </UFormGroup>

      <div v-if="blok?.showReferralField" class="md:col-span-2 flex flex-col gap-7">
        <UFormGroup label="Referral Email" name="referral_email">
          <UInput v-model="state.referral_email" />
        </UFormGroup>
      </div>

      <div class="md:col-span-2 flex flex-col gap-7">
        <UFormGroup name="requested_walkthrough" class="font-bold">
          <UCheckbox v-model="state.requested_walkthrough" name="requested_walkthrough" label="I want a walkthrough or tour" :ui="{ label: 'font-bold' }" />
        </UFormGroup>
        <UFormGroup name="email_optin">
          <UCheckbox v-model="state.email_optin" name="email_optin" label="I understand that I may be contacted via email, and would like information, tips, and offers about Kudosity products and services." />
        </UFormGroup>
        <UFormGroup name="sms_optin">
          <UCheckbox v-model="state.sms_optin" name="sms_optin" label="I understand that I may be contacted via SMS, at the mobile phone number provided, and would like to receive notifications, offers, surveys and feedback requests from Kudosity." />
        </UFormGroup>
        <button type="submit" class="btn btn-primary btn-submit w-max mt-4" :class="{ 'btn-loading': loading }">Create Account</button>
        <p>To learn more, view the <a href="/legal/privacy-policy" class="underline">Privacy Statement</a> and <a href="/legal/terms-and-conditions" class="underline">Terms and Conditions</a></p>
      </div>
    </template>
    <template v-else>
      <div class="md:col-span-2 flex flex-col gap-7">
        <p class="text-xl" v-html="hideFormNotice"></p>
      </div>
    </template>
  </UForm>
</template>

<script setup lang="ts">
  import countries from "~/content/data/regions.json";
  import { parsePhoneNumberWithError, validatePhoneNumberLength, ParseError } from "libphonenumber-js/max";
  import phoneErrorCodes from "~/content/data/phone-error-codes.js";
  import Joi from "joi";
  import type { FormError, FormErrorEvent, FormSubmitEvent } from "#ui/types";

  const { setItem: setUser } = useLocalStorage();
  const is_exception = ref(false);
  const is_timeout = ref(false);
  const props = defineProps({ blok: Object });
  const isPreview = useRuntimeConfig().public.CMS_ENV !== "production";

  const { init: initialiseRecaptcha } = useRecaptcha();

  const customer_status = ["New", "Existing"];

  const options = ref({
    checkForBlockedDomains: props?.blok?.checkForBlockedDomains,
    checkForBlockedEmails: props?.blok?.checkForBlockedEmails,
    checkForExcludeList: props?.blok?.checkForExcludeList,
  });

  const loading = ref(false);

  const formClass = computed(() => {
    const classList = [];

    if (loading.value) {
      classList.push("pointer-events-none");
      classList.push("opacity-50");
    }

    return classList.join(" ");
  });

  const config = useRuntimeConfig().public;

  const form = ref();

  const captcha_score = ref(0);

  const emailDomainValid: Ref<boolean | string> = ref("");

  const schema = Joi.object({
    first_name: Joi.string().required().label("First Name"),
    last_name: Joi.string().required().label("Last Name"),
    company: Joi.string().required().label("Company Name"),
    company_website: Joi.string()
      .pattern(new RegExp(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})((\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?)$/))
      .required()
      .label("Company Website")
      .messages({
        "string.pattern.base": "Provide a valid website url",
      }),
    country: Joi.object().required().label("Country"),
    phone: Joi.string().required().label("Mobile Number"),
    email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required().label("Email"),
    referral_email: Joi.string().email({ minDomainSegments: 2, tlds: false }).optional().allow(null, "").label("Referral Email"),
    customer_status: Joi.string().optional().allow(null, "").label("Customer Status"),
    email_optin: Joi.boolean().valid(true).required(),
    sms_optin: Joi.boolean().valid(true).required(),
    platform_use: Joi.string().required().label("Platform Use").messages({
      "string.base": "Please select an option",
    }),
    requested_walkthrough: Joi.boolean().optional().allow(null, ""),
  }).prefs({
    messages: {
      "any.required": "Please provide your {{#label}}",
      "any.object": "Please provide your {{#label}}",
      "object.required": "Please provide your {{#label}}",
      "string.email": "Please provide a valid email",
      "string.hostname": "Please provide a valid website",
      "string.empty": "Please provide your {{#label}}",
      "any.only": "Please accept this term to continue",
    },
  });

  const state = reactive({
    first_name: "",
    last_name: "",
    company: "",
    country: countries[0],
    company_website: "",
    phone: "",
    email: "",
    referral_email: "",
    customer_status: "New",
    email_optin: false,
    platform_use: "",
    sms_optin: false,
    requested_walkthrough: false,
  });

  const platform_use = [
    {
      label: "I'll use Kudosity's platform directly",
      value: "direct",
    },
    {
      label: "I'll integrate with my systems via API.",
      value: "api",
    },
  ];

  const location = ref({
    ip: null,
    country_code: null,
    country_name: null,
    time_zone: null,
    timestamp: "",
    latitude: null,
    longitude: null,
    zip: null,
  });

  const hideFormNotice = ref("Sorry, this offer is for <b>new</b> customers only.");

  const showForm = computed(() => {
    if (props.blok?.showNewCustomerField && props.blok?.allowNewOnly && state.customer_status === "Existing") return false;
    return true;
  });

  const validate = async (state: any): Promise<FormError<string>[]> => {
    const errors: FormError<string>[] | PromiseLike<FormError<string>[]> = [];

    if (props.blok?.showNewCustomerField && state.customer_status === "Existing") return errors;

    if (state.phone?.length > 0) {
      let phoneError = 'Please provide a valid "Mobile Number"';
      try {
        const parsedPhone = parsePhoneNumberWithError(state.phone, { defaultCountry: state.country?.code || "AU" });
        const phoneIsValid = parsedPhone.isValid();
        const phoneType = parsedPhone.getType();
        const phoneLength = validatePhoneNumberLength(state.phone, state.country?.code || "AU");

        if (phoneLength === undefined) {
          if (phoneIsValid && phoneType?.includes("MOBILE")) {
            state.phone = parsedPhone.nationalNumber;
            phoneError = "";
          }
        }
      } catch (error) {
        if (!(error instanceof ParseError)) {
          throw error;
        }
      }

      if (phoneError) {
        errors.push({ path: "phone", message: phoneError });
      }
    }

    return errors;
  };

  const checkProfanity = async () => {
    const { checkProfanity } = useCheckProfanity();

    const profanity = checkProfanity({
      first_name: state.first_name,
      last_name: state.last_name,
      company: state.company,
      company_website: state.company_website,
      email: state.email,
    });

    if (typeof profanity === "object") {
      setFieldError(profanity.key, "Please provide a valid entry");
      return false;
    }
    return true;
  };

  const checkForm = async () => {
    const checks = [
      { name: "checkProfanity", func: checkProfanity },
      { name: "recaptcha", func: validateRecaptcha },
      { name: "emailPattern", func: checkEmailPattern },
      { name: "accountValidation", func: checkAccountValidation },
      { name: "phoneValidity", func: checkPhoneValidity },
      { name: "emailValidity", func: checkEmailValidity },
    ];

    const results: Record<string, boolean | undefined> = {};

    try {
      for (const { name, func } of checks) {
        const result: boolean | undefined = await func();
        results[name] = result;
        if (!result) break;
      }
    } catch (error) {
      console.error("Form check error:", error);
      throw error;
    } finally {
      loading.value = false;
    }

    return results;
  };

  const validateDomain = async () => {
    // NON-FAILING CHECKS - Still continue to submit regardless of the result
    const { check: checkEmailCompanyScore } = useCheckEmailCompanyScore();

    // 1. Email Company Website Score
    const isValidEmailCompany = await checkEmailCompanyScore({ email: state.email, company_website: state.company_website });
    emailDomainValid.value = isValidEmailCompany;
  };

  async function onSubmit() {
    loading.value = true;
    form.value.clear();

    if (props.blok?.showNewCustomerField && state.customer_status === "Existing") {
      try {
        await validateRecaptcha();
        await recordZapier({ status: "success", error: "Form Submission EE " + (is_exception.value ? ". Email Exception." + (is_timeout.value ? " API Timeout." : "") : "") });

        window.location.assign(`${props?.blok?.success_page}-existing`);
      } catch (error) {
        console.error("onSubmit An unexpected error occurred:", error);
      }
      loading.value = false;
      return;
    }

    try {
      const results = await checkForm();

      if (form.value.errors?.length > 0) return;

      if (emailDomainValid.value === "") await validateDomain();

      if (Object.values(results).every(Boolean)) {
        console.log("onSubmit SUCCESS");
        await sendPardot();
      } else {
        console.log("onSubmit Some checks failed");
      }
    } catch (error) {
      console.error("onSubmit An unexpected error occurred:", error);
    }
    loading.value = false;
  }

  const validateRecaptcha = async () => {
    try {
      const threshold = 0.75;
      const recaptchaResponse = await useGoogleRecaptcha("register", threshold);
      console.log("validateRecaptcha", recaptchaResponse);

      captcha_score.value = recaptchaResponse?.score;

      if (!recaptchaResponse || recaptchaResponse?.score <= threshold) {
        // throw new Error("validateRepatcha:failed");
        return false;
      }
      return true;
    } catch (error) {
      handleEmailPhoneErrors("API_FAIL");
      throw error;
    }
  };

  const checkEmailPattern = async () => {
    const { isEmailPatternBlocked, isEmailDomainBlocked } = useCheckEmail();

    if (await isEmailPatternBlocked(state.email)) {
      setFieldError("email", "Please provide a valid email address");

      await sendSlackNotification({ type: "email", message: "Check Email Pattern. Blocklisted" });
      // throw new Error("checkEmailPattern:failed", { cause: "Please provide a valid email address" });
      return false;
    }
    if (await isEmailDomainBlocked(state.email)) {
      setFieldError("email", "Please provide a valid email address");
      await sendSlackNotification({ type: "email", message: "Check Email Pattern. Blocklisted" });
      // throw new Error("checkEmailPattern:failed", { cause: "Please provide a valid email address" });
      return false;
    }

    return true;
  };

  const checkPhoneValidity = async () => {
    try {
      const { data: checkPhone, error } = await useCheckPhone(phoneFull.value, state.country.code);
      if (error.value) throw error.value;
      if (checkPhone.value) {
        if (checkPhone.value?.status === "Too many requests. Please try again later.") {
          console.log("checkPhoneValidity:useCheckPhone", checkPhone.value);
          handleEmailPhoneErrors("BANNED_NUMBER");
          return false;
        }
        let errorCodeMessage = "";

        if (phoneErrorCodes[checkPhone.value?.rc] !== null) {
          errorCodeMessage = phoneErrorCodes[checkPhone.value?.rc] ?? `RC:${checkPhone.value?.rc}`;
        }

        if (checkPhone.value?.rc === "000") {
          // Phone Valid. Proceed to email checking
          console.log("checkPhoneValidity:useCheckPhone VALID", checkPhone.value);
          return true;
        } else {
          console.log("checkPhoneValidity:useCheckPhone rc", checkPhone.value, errorCodeMessage);
          handleEmailPhoneErrors("BANNED_NUMBER");

          await callRegistrationFailed({
            type: "phone",
            email: state.email,
            phone: phoneFull.value,
            data: checkPhone.value,
            error: errorCodeMessage,
          });

          // throw new Error("checkPhoneValidity:failed", { cause: "BANNED_NUMBER" });
          return false;
        }
      } else {
        console.log("checkPhoneValidity:useCheckPhone", checkPhone.value);
        handleEmailPhoneErrors("API_FAIL");

        await callRegistrationFailed({
          type: "phone",
          email: state.email,
          phone: phoneFull.value,
          data: null,
          error: "API_FAIL",
        });

        // throw new Error("checkPhoneValidity:failed", { cause: "API_FAIL" });
        return false;
      }
    } catch (error: any) {
      console.error(error);
      console.log("checkPhoneValidity:useCheckPhone", error.message);

      useBugsnag().notify(error, (event) => {
        event.addMetadata({
          type: "phone",
          email: state.email,
          phone: phoneFull.value,
          data: JSON.stringify(error),
          error: "API_FAIL",
        });
      });

      if (
        String(error.data || error.message)
          .toLowerCase()
          .includes("timeout")
      ) {
        is_exception.value = true;
        is_timeout.value = true;
        return true;
      }

      handleEmailPhoneErrors("API_FAIL");

      throw error;
    }
  };

  const checkEmailValidity = async () => {
    const { isEmailValid } = useCheckEmail();

    try {
      const { data: isEmailValidResponse, error } = await isEmailValid(state.email, options.value);

      if (error.value) throw error.value;

      is_exception.value = false;

      if (isEmailValidResponse.value) {
        if (isEmailValidResponse.value?.status === "Too many requests. Please try again later.") {
          console.error("checkEmailValidity:isEmailValidResponse", isEmailValidResponse.value);
          handleEmailPhoneErrors("INVALID_EMAIL");
          return false;
          // throw new Error("checkEmailValidity:failed", { cause: "INVALID_EMAIL" });
        }

        const validCodes = ["valid", "catch-all"];
        const exceptions = ["role_based", "role_based_catch_all", "greylisted"];

        if (validCodes.includes(isEmailValidResponse.value?.status)) {
          // Email Valid. Proceed to send registration
          console.log("checkEmailValidity:isEmailValidResponse VALID", isEmailValidResponse.value);
          return true;
        } else if (exceptions.includes(isEmailValidResponse.value?.sub_status)) {
          // Email Valid with exceptions. Proceed to send registration
          is_exception.value = true;
          console.error("checkEmailValidity:isEmailValidResponse VALID:EXCEPTION", isEmailValidResponse.value);
          return true;
        } else {
          const catchCodes = ["do_not_mail"];

          if (catchCodes.includes(isEmailValidResponse.value?.status)) {
            console.error("checkEmailValidity:isEmailValidResponse CATCH_EMAIL", isEmailValidResponse.value);
            handleEmailPhoneErrors("CATCH_EMAIL");

            await callRegistrationFailed({
              type: "email",
              email: state.email,
              phone: phoneFull.value,
              data: isEmailValidResponse.value,
              error: "CATCH_EMAIL",
            });
            // throw new Error("checkEmailValidity:failed", { cause: "CATCH_EMAIL" });
            return false;
          } else {
            console.error("checkEmailValidity:isEmailValidResponse INVALID_EMAIL", isEmailValidResponse.value);
            handleEmailPhoneErrors("INVALID_EMAIL");

            await callRegistrationFailed({
              type: "email",
              email: state.email,
              phone: phoneFull.value,
              data: isEmailValidResponse.value,
              error: "INVALID_EMAIL",
            });

            // throw new Error("checkEmailValidity:failed", { cause: "INVALID_EMAIL" });
            return false;
          }
        }
      } else {
        await callRegistrationFailed({
          type: "email",
          email: state.email,
          phone: phoneFull.value,
          data: null,
          error: "API_FAIL",
        });
      }
    } catch (error: any) {
      console.error(error);
      console.log("checkEmailValidity:isEmailValidResponse", error.message);

      useBugsnag().notify(error, (event) => {
        event.addMetadata({
          type: "email",
          email: state.email,
          phone: phoneFull.value,
          data: JSON.stringify(error),
          error: "API_FAIL",
        });
      });

      if (
        String(error.data || error.message)
          .toLowerCase()
          .includes("timeout")
      ) {
        is_exception.value = true;
        is_timeout.value = true;
        return true;
      }

      handleEmailPhoneErrors("API_FAIL");

      await callRegistrationFailed({
        type: "email",
        email: state.email,
        phone: phoneFull.value,
        data: null,
        error: "API_FAIL",
      });

      throw error;
    }

    throw new Error("checkEmailValidity:failed", { cause: "INVALID_EMAIL" });
  };

  const checkAccountValidation = async () => {
    const { isAccountValid } = useCheckAccount();
    try {
      const isAccountValidResponse = await isAccountValid(state.email, phoneFull.value);

      if (isAccountValidResponse && typeof isAccountValidResponse == "object" && isAccountValidResponse.length !== 0) {
        handleEmailPhoneErrors(isAccountValidResponse[0]);
        // throw new Error("checkAccountValidation:failed", { cause: isAccountValidResponse });
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      console.log("checkAccountValidation:isAccountValid", error.message);

      if (!error.message?.includes(":failed")) {
        handleEmailPhoneErrors("API_FAIL");
      }

      throw error;
    }
  };

  const handleEmailPhoneErrors = (result: string) => {
    switch (result) {
      case "EXISTING_ACCOUNT":
        setFieldError("email", "Email address already in use. Please <a href='https://burst.transmitsms.com/login' target='_blank' class='underline'>log in</a> or <a href='https://portal.burstsms.com/s/submit-ticket' target='_blank' class='underline'>contact us</a> for support.");
        break;
      case "INVALID_EMAIL_PATTERN":
      case "INVALID_EMAIL":
        setFieldError("email", "Email Address is not accepted. Please <a href='https://portal.burstsms.com/s/submit-ticket' target='_blank' class='underline'>contact us</a> for support.");
        break;
      case "INVALID_DOMAIN":
        setFieldError("email", "Email domain is not accepted. Please <a href='https://portal.burstsms.com/s/submit-ticket' target='_blank' class='underline'>contact us</a> for support.");
        break;
      case "CATCH_EMAIL":
        setFieldError(
          "email",
          "We cannot accept <span class='x-tooltip'>catch all<div class='x-tooltip-html'><div class='x-tooltip-close'>&times;</div>Because our application uses your email for functions such as billing and alerts you need to use a real email address, catch all or aliased emails such as info@ or support@ get blocked by certain email systems and important communications can get lost. If you do not have access to a real email address you can talk to <a href='https://contact.burstsms.com' target='_blank' class='underline'>Sales</a> or contact <a href='https://portal.burstsms.com/s/submit-ticket' target='_blank' class='underline'>Support</a>.</div></span> emails for registration."
        );
        break;

      case "VIRTUAL_NUMBER":
      case "BANNED_NUMBER":
        setFieldError("phone", 'The number you have entered is invalid. Please try a different number or <a href="#live-chat" class="underline">contact us</a> for support.');
        break;

      case "EXISTING_NUMBER":
        setFieldError("phone", "The number you have entered is already in use. For support or if you need a second account, <a href='https://portal.burstsms.com/s/submit-ticket' class='underline' target='_blank'>contact us</a>.");
        break;

      case "API_FAIL":
      default:
        setFieldError("email", "We encountered an error while processing your request. Please <a class='underline' href='https://portal.burstsms.com/s/submit-ticket' target='_blank'>contact us</a> for support.");
        break;
    }
  };

  const phoneFull = computed(() => {
    return (state.country?.dial || "61") + state.phone;
  });

  const formHandlerURL = computed(() => {
    if (isPreview) return config.SF_WEB2LEAD_URL;
    return props.blok?.form_handler || config.SF_PARDOT;
  });

  async function sendPardot() {
    loading.value = true;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const params = {
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      company_name: state.company,
      mobile: phoneFull.value,
      email_opt_in: state.email_optin,
      sms_opt_in: state.sms_optin,
      website_url: window?.location.href ?? "https://kudosity.com/trial", // Website URL
      source_referrer: document?.referrer ?? window?.location.href, // Source Referrer
      website_source: "Trial", // Website_S__c
      referral_email: state.referral_email || "", // Referral Email Address
      country: state.country.name,
      country_code: state.country?.code || "AU", // Source__c
      geo_country_code: location.value.country_code || "AU", // Country_Code__c
      timezone: location.value.time_zone || timezone || "", // Timezone__c
      success_location: props.blok?.success_page || "/trial/success",

      utm_campaign: "",
      utm_content: "",
      utm_medium: "",
      utm_source: "",
      utm_term: "",
      gclid: "",

      email_domain_validity: emailDomainValid.value,
      platform_use: state.platform_use,
      company_website: state.company_website,
      requested_walkthrough: state.requested_walkthrough,
    };

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    params.utm_campaign = searchParams.get("utm_campaign") || "";
    params.utm_content = searchParams.get("utm_content") || "";
    params.utm_medium = searchParams.get("utm_medium") || "";
    params.utm_source = searchParams.get("utm_source") || "";
    params.utm_term = searchParams.get("utm_term") || "";
    params.gclid = searchParams.get("gclid") || "";

    if (isPreview) {
      params.company = params.company_name;
      params.phone = params.mobile;

      params.oid = "00D1e0000000qyc";
      params["00N5i00000Rj1cj"] = params.utm_campaign;
      params["00N5i00000Rj1i2"] = params.utm_medium;
      params["00N5i00000Rj1jS"] = params.utm_source;
      params["00N5i00000Rj1jn"] = params.utm_content;
      params["00N5i00000Rj1ne"] = params.utm_term;
      params["00N5i00000JLBIt"] = params.website_url;
      params["00N5i00000JPIOy"] = params.geo_country_code;
      params["00N5i000009uOj2"] = params.country_code;
      params["00N5i000009uOj2"] = params.country_code;
      params["00N5i00000Cl5QK"] = params.website_source;
      params["00N5i000009uOiY"] = params.source_referrer;
      params["00N5i00000ClpKr"] = params.timezone;
      params.retURL = `${config.SITE_URL}${params.success_location}`;
    }

    setUser("kudosity-register", {
      name: params.first_name,
      email: params.email,
      region: params.country,
      country: params.country_code,
      company: params.company_name,
      phone: params.mobile,
      reseller: params.country_code,
    });

    const form = document.createElement("form");
    form.style.display = "none";
    form.setAttribute("method", "POST");
    form.setAttribute("action", formHandlerURL.value);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);

    await sendSlackNotification({
      type: "success",
      message: "Form Submission" + (is_exception.value ? ". Email Exception." + (is_timeout.value ? " API Timeout." : "") : ""),
    });

    form.submit();
  }

  async function onError(event: FormErrorEvent) {
    console.log("onError", event);
    const element = document.getElementById(event.errors[0].id);
    element?.focus();
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  // Validations

  const setFieldError = (path: string, message: string) => {
    form.value.setErrors([{ path, message }], path);
  };

  const inputPhonePadding = computed(() => {
    if (state.country?.dial?.length > 2) return "ps-14 text-lg";
    if (state.country?.dial?.length > 1) return "ps-12 text-lg";
    return "ps-9 text-lg";
  });

  const onPhoneInput = (event: InputEvent) => {
    const target = event?.target as HTMLInputElement;
    state.phone = target.value = target.value.replaceAll(/[^0-9]/g, "");
  };

  const callRegistrationFailed = async (data: object) => {
    loading.value = false;

    const notificationData = {
      type: "error",
      message: "Registration Error",
    };

    if (data.type === "email") {
      if (data?.data?.status) notificationData.message = "Check Email. " + data?.data?.status + " " + data?.data?.sub_status;
      else notificationData.message = "Check Email. " + JSON.stringify(data);
    }

    if (data.type === "phone") {
      notificationData.message = "Check Phone. " + data.error;
    }

    console.log("Notification:callRegistrationFailed", data, notificationData);

    // Trigger Slack Call
    await sendSlackNotification(notificationData);
  };

  const sendSlackNotification = async (data: object) => {
    await recordZapier({ status: data.type, error: data.message });

    const { data: sendSlack, error } = await useSendSlack({
      ...data,
      url: window.location.href,
      name: state.first_name + " " + state.last_name,
      email: state.email,
      company: state.company,
      phone: phoneFull.value,
      country: state.country.name,
      company_website: state.company_website,
      requested_walkthrough: state.requested_walkthrough,
      platform_use: state.platform_use,
    });
    return sendSlack;
  };

  const recordZapier = async (overrides: object) => {
    const authFetch = useAuthFetch();

    const data = {
      form: {
        status: "Success",
        error: "-",
        location: location.value,
        country: state.country.name,
        country_code: state.country.code,
        name: state.first_name + " " + state.last_name,
        name_company: state.company,
        email: state.email,
        phone: phoneFull.value,
        source: window?.location.href || "https://kudosity.com",
        referral_email: state.referral_email,
        customer_status: state.customer_status,
        captcha_score: captcha_score.value || "-",
      },
    };

    if (overrides) {
      data.form = { ...data.form, ...overrides };
    }

    return await authFetch(config.FT_ZAP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  onMounted(() => {
    if (import.meta.client) {
      initialiseRecaptcha();

      const { getItem } = useLocalStorage();
      const timestamp = new Date().toISOString().split(".")[0].replace("T", " ");

      const kudogeo = getItem("kudogeo");

      if (kudogeo) {
        const { country_name, time_zone, latitude, longitude, zipcode, ip } = kudogeo.data;
        location.value = { country_name, time_zone: time_zone?.name, latitude, longitude, zip: zipcode, ip, country_code: kudogeo.country_code };
      }

      location.value.timestamp = timestamp;

      // Check if someone is trying to spam the form
      const sensitiveParams = ["email", "phone", "company", "first_name", "last_name"];

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      const hasSensitiveParam = sensitiveParams.some((param) => params.has(param));

      if (hasSensitiveParam) {
        navigateTo("/unavailable");
      }

      if (params.has("by")) {
        state.referral_email = params.get("by");
      }
    }
  });
</script>
